<template>
  <div>
    <div>
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div v-if="hasPermissionToRead">

            <b-card
              no-body
              class="mb-0"
            >

              <div class="mr-2 ml-2 mt-1 mb-1">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <div>
                      <b-form-group
                        label="Exibir"
                        label-for="idSearchBox"
                        class="mb-0"
                      >
                        <v-select
                          id="idSearchBox"
                          v-model="perPage"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="perPageOptions"
                          :clearable="false"
                          class="per-page-selector d-inline-block"
                        >
                          <span slot="no-options">Não há opções para essa quantidade.</span>
                        </v-select>
                      </b-form-group>
                    </div>
                    <b-form-input
                      v-model.lazy="searchQuery"
                      placeholder="Buscar"
                      type="text"
                      class="d-inline-block mt-2 ml-1"
                    />
                  </b-col>
                </b-row>
              </div>
              <b-table
                class="position-relative"
                primary-key="id"
                responsive
                show-empty
                striped
                hover
                empty-text="Não há Órgão cadastrado."
                :items="clients"
                :fields="tableColumns"
                :busy="isLoading"
                :sort-by.sync="sortOptions.sortBy"
                :sort-desc.sync="sortOptions.isSortDirDesc"
                @row-clicked="onRowSelected"
              >
                <template #table-busy>
                  <div class="text-center my-3">
                    <b-spinner
                      variant="info"
                      class="align-middle mb-1"
                    />
                    <br>
                    <strong class="pt-2">Carregando ...</strong>
                  </div>
                </template>

                <template #cell(id)="data">
                  <b-media vertical-align="center">
                    <b-link
                      class="font-weight-bold d-block text-nowrap"
                      @click="onRowSelected(data.item)"
                    >
                      #{{ data.item.id }}
                    </b-link>
                  </b-media>
                </template>

                <template #cell(name)="data">
                  <b-media vertical-align="center">
                    <span class="font-weight-bold text-nowrap">
                      {{ data.item.name | truncate(40, '...') }}
                    </span>
                  </b-media>
                </template>

                <template #cell(cnpj)="data">
                  <div
                    v-if="data.item.cnpj"
                  >
                    {{ data.item.cnpj | VMask('##.###.###/####-##') }}
                  </div>
                  <div v-else>
                    <span class="font-weight-bold">-</span>
                  </div>
                </template>

                <template #cell(ie)="data">
                  <div
                    v-if="data.item.ie"
                  >
                    {{ data.item.ie }}
                  </div>
                  <div v-else>
                    <span class="font-weight-bold">-</span>
                  </div>
                </template>

                <template #cell(phone_number)="data">
                  <div
                    v-if="data.item.phone_number"
                  >
                    {{ data.item.phone_number }}
                  </div>
                  <div v-else>
                    <span class="font-weight-bold">-</span>
                  </div>
                </template>

                <template #cell(city__name)="data">
                  <div
                    v-if="data.item.city_set"
                    class="text-nowrap"
                  >
                    <span class="align-text-top text-capitalize">{{ data.item.city_set.name }}</span>
                  </div>
                  <div
                    v-else
                    class="text-nowrap"
                  >
                    <span class="font-weight">-</span>
                  </div>
                </template>
                <template #cell(state__name)="data">
                  <div
                    v-if="data.item.state_set"
                    class="text-nowrap"
                  >
                    <span class="align-text-top text-capitalize">{{ data.item.state_set.code }}</span>
                  </div>
                  <div
                    v-else
                    class="text-nowrap"
                  >
                    <span class="align-text-top text-capitalize">-</span>
                  </div>
                </template>

                <!-- Column: Actions -->
                <template #cell(field_actions)="data">
                  <div
                    :class="totalItems > 1 ? '' : 'dropdownfix'"
                  >
                    <b-dropdown
                      id="actions"
                      variant="link"
                      no-caret
                      :dropup="true"
                      :boundary="'scrollParent'"
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>

                      <b-dropdown-item
                        :to="{ name: 'client-detail', params: { id: data.item.id }}"
                      >
                        <feather-icon icon="FileTextIcon" />
                        <span class="align-middle ml-50">Detalhes</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        :disabled="!hasPermissionToUpdate"
                        :to="{ name: 'client-edit', params: { id: data.item.id } }"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Editar</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        :disabled="!hasPermissionToDelete"
                        @click="showConfirmOrCancelDelete(data.item)"
                      >
                        <svg-icon
                          type="mdi"
                          size="16"
                          :path="mdiTrashCanOutline"
                        />
                        <span class="align-middle ml-50">Remover</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col
                    cols="12"
                    class="d-flex align-items-center justify-content-center"
                  >

                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalItems"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <div>
        <b-button
          id="addClientBtn"
          v-b-tooltip.hover
          class="btn-icon rounded-circle m-3"
          variant="primary"
          size="md"
          :to="{ name: 'client-create'}"
          :disabled="!hasPermissionToWrite"
          title="Adicionar órgão"
        >
          <svg-icon
            type="mdi"
            :path="mdiPlus"
          />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'

import {
  mdiTableColumn,
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SvgIcon from '@jamescoyle/vue-icon'
import router from '@/router'
import syslic from '@/syslic'
import truncate from '@/components/utils/filters/truncate'
import MetaData from '@/components/utils/metaData'
import debounce from '@/components/utils/debounce'

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    SvgIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    truncate,
  },
  data() {
    const perPage = 10
    const totalItems = 0
    const currentPage = 1
    const searchQuery = ''

    const sortOptions = {
      sortBy: 'name',
      isSortDirDesc: false,
    }

    const perPageOptions = [10, 25, 50, 100]

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        sortable: true,
      },
      {
        key: 'name',
        label: 'Razão Social',
        sortable: true,
      },
      {
        key: 'cnpj',
        label: 'CNPJ',
        sortable: true,
      },
      {
        key: 'ie',
        label: 'IE',
        sortable: true,
      },
      {
        key: 'phone_number',
        label: 'Telefone',
        sortable: true,
      },
      {
        key: 'city__name',
        label: 'Cidade',
        sortable: true,
      },
      {
        key: 'state__name',
        label: 'Estado',
        sortable: true,
      },
      {
        key: 'field_actions',
        label: '',
        sortable: false,
      },
    ]
    const clients = []

    const isLoading = true

    const fetchId = ''

    const meta = new MetaData('ClientTable')

    return {
      perPage,
      totalItems,
      currentPage,
      searchQuery,
      perPageOptions,
      tableColumns,
      clients,
      isLoading,
      fetchId,
      sortOptions,
      meta,
    }
  },
  computed: {
    hasPermissionToRead() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.client.can_read
    },
    hasPermissionToWrite() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.client.can_write
    },
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.client.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.client.can_delete
    },
  },
  watch: {
    perPage: 'fetchData',
    currentPage: 'fetchData',
    searchQuery: function updateSearchQuery(value) {
      this.currentPage = 1
      this.searchQuery = value
      this.fetchData()
    },
    'sortOptions.sortBy': 'fetchData',
    'sortOptions.isSortDirDesc': 'fetchData',
  },
  created() {
    this.sortOptions = this.meta.bind(this.sortOptions, 'sortOptions')
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.isLoading = true
      debounce.call(this.fetch)
    },
    fetch() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending

      const params = {
        p: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
      }

      if (this.sortOptions.isSortDirDesc) {
        params.ordering = `-${this.sortOptions.sortBy}`
      } else {
        params.ordering = this.sortOptions.sortBy
      }

      syslic
        .client
        .fetchClientList(params)
        .then(response => {
          if (fetchPending === this.fetchId) {
            this.totalItems = response.data.count
            this.clients = response.data.results

            this.isLoading = false
          }
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados dos órgãos.',
                text: 'Não foi possível ler os dados dos Órgãos no servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: false,
              },
            })
            this.isLoading = false
          }
        })
    },
    deleteClient(item) {
      syslic
        .client
        .deleteClient(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Órgão removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.clients.splice(this.clients.indexOf(item), 1)
        })
        .catch(error => {
          let textError = 'Não foi possível remover o órgão do servidor, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Este órgão é referenciado em alguma licitação, por este motivo não será possível exclui-lo.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover órgão.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Por favor, confirme que você deseja remover este órgão.', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteClient(item)
          }
        })
    },
    onRowSelected(item) {
      router.push({ name: 'client-detail', params: { id: item.id } }).catch(() => {})
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiTableColumn,
      mdiPlus,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.dropdownfix .dropdown.show{
  position: fixed;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#addClientBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
